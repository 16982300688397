<template>
	<article class="row-data">
		<figure v-if="!certificateLayout" class="row-data-image-container">
			<img class="row-data-image" :src="courseImage" alt="" />
		</figure>
		<p class="row-data-name primary-text">{{ name }}</p>
		<div
			v-if="dateStart"
			class="row-data-date row-data-date--left primary-text"
		>
			<span>START DATE</span>
			<p class="mb-0">{{ start }}</p>
		</div>
		<div
			v-if="dateComplete"
			class="row-data-date row-data-date--right primary-text"
		>
			<span>COMPLETE DATE</span>
			<p class="mb-0">{{ end }}</p>
		</div>
		<div
			v-if="!certificateLayout"
			class="row-data-date row-data-date--right primary-text"
		>
			<span>{{ status }}</span>
		</div>
		<figure class="row-data-buttons-container">
			<v-btn
				class="admin-primary-button primary-contrast-background"
				@click="$emit('downloadCetificate', courseId)"
			>
				{{
					certificateLayout ? "See Cetificate" : "Download Cetificate"
				}}
			</v-btn>
			<v-btn
				v-if="!certificateLayout"
				class="admin-primary-button primary-contrast-background"
				@click="$emit('startCourse', courseId)"
				>{{ buttonCourseText }}</v-btn
			>
		</figure>
		<CertificateTemplate
			v-if="displayPopup === true"
			:userName="userName"
			:courseTitle="name"
			:completed="dateComplete !== null"
			:dateCompleted="dateComplete"
			@closePopup="$emit('closePopup')"
		/>
	</article>
</template>

<script>
import { mapGetters } from "vuex";
import CertificateTemplate from "../../common/CertificateTemplate.vue";

export default {
	name: "StudentCourseList",
	components: {
		CertificateTemplate
	},
	props: {
		image: {
			type: Array,
			require: false,
			default: null
		},
		name: {
			type: String,
			require: true
		},
		dateStart: {
			type: String,
			require: false
		},
		courseId: {
			type: Number,
			require: false
		},
		dateComplete: {
			type: String,
			require: false
		},
		status: {
			type: String,
			require: false
		},
		certificateLayout: {
			type: Boolean,
			require: false,
			default: false
		},
		displayPopup: {
			type: Boolean,
			require: false,
			default: false
		},
		userName: {
			type: String,
			require: false
		}
	},

	computed: {
		...mapGetters(["startedCourses"]),
		start() {
			return new Date(this.dateStart).toLocaleDateString();
		},

		end() {
			return new Date(this.dateComplete).toLocaleDateString();
		},
		courseImage() {
			if (!!this.image && this.image.length === 0) {
				return "https://e7.pngegg.com/pngimages/329/915/png-clipart-computer-icons-educational-technology-learning-training-course-training-blue-angle.png";
			} else {
				return this.image[0].image.public_url;
			}
		},
		buttonCourseText() {
			if (
				this.startedCourses.find(el => el.course.id === this.courseId)
					?.graded
			) {
				return "Retake";
			} else if (
				this.startedCourses.find(el => el.course.id === this.courseId)
			) {
				return "Resume";
			} else {
				return "Take Course";
			}
		}
	}
};
</script>
